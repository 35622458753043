export const ru = {
  navbar1: "Главная",
  navbar2: "О нас",
  navbar3: "Товары",
  navbar4: "Контакты",

  aboutUs: "О НАШЕЙ КОМПАНИИ SEENTEX",
  aboutUsP: `'Компания "SEENTEX" начала свою деятельность в Узбекистане в 2022 году, официальное открытие состоялось в мае.
  Основной целью нашего предприятия является завоевание места в домах и сердцах наших клиентов с помощью качественной и доступной продукции, основанной на требованиях наших людей. В настоящее время налажена поставка по всей республике широкого ассортимента смесителей и газовых котлов под брендом.`,

  benefist: "НАШИ ПРЕИМУЩЕСТВА",
  benefist1: "ВЫСОКОЕ КАЧЕСТВО ПО ДОСТУПНЫМ ЦЕНАМ",
  benefist2: "ЭКОЛОГИЧЕСКИ ЧИСТЫЕ И БЕЗОПАСНЫЕ ТОВАРЫ",
  benefist3: "СОТРУДНИЧЕСТВО С МЕЖДУНАРОДНЫМИ КОМПАНИЯМИ",
  benefist4: "ОТЛИЧНЫЙ ПОДАРОК И ВАУЧЕРЫ ДЛЯ НАШИХ КЛИЕНТОВ",

  whyWe: "Почему SEENTEX? ",
  whyWeP: "Изготовлен из высококачественного латуна по итальянской технологии и дизайну",
  garantiya: "Гарантия",
  garantiyaP: "Наши продукции имеет соответствующие сертификаты ",

  believe: "Наши партнеры",

  ourAddress: "Наш адрес:",
  address: " Оптом - Урикзор Строй город 3 блок 10/61.",
  address2: "Pынок Карвон г.Чирчик.",
  address3: "Филиал Кибрай",
  day: "Пн-Сб ",
  day2: "Вт-Вс",
  toxir: "Тохир",
  navruzbek: "Наврузбек",
  kamoliddin: "Камолиддин",
  galereya: "Галерея",

  footer: "Высокое качество продукта",
  footer1: "Mеню",
  footer2: "Cоц.сети",


  category_1: "Cмесители",
  category_2: "Kотельная",
};