export const en = {
    navbar1: "Main",
    navbar2: "About us",
    navbar3: "Products",
    navbar4: "Contacts",
  
    aboutUs: "ABOUT SEENTEX",
    aboutUsP: `'The SEENTEX company began its activities in Uzbekistan in 2022, the official opening took place in May.
    The main goal of our enterprise is to win a place in the homes and hearts of our customers with quality and affordable products based on the requirements of our people. At present, the supply of a wide range of mixers and gas boilers under the brand has been arranged throughout the republic.`,
  
    benefist: "OUR ADVANTAGES",
    benefist1: "HIGH QUALITY AT AFFORDABLE PRICES",
    benefist2: "ENVIRONMENTALLY FRIENDLY AND SAFE PRODUCTS",
    benefist3: "COOPERATION WITH INTERNATIONAL COMPANIES",
    benefist4: "GREAT GIFT AND VOUCHERS FOR OUR CLIENTS",
  
    whyWe: "Why SEENTEX?",
    whyWeP: "Made from high quality brass using Italian technology and design",
    garantiya: "Guarantee",
    garantiyaP: "Our products have the appropriate certificates",
  
    believe: "Our partners",
  
    ourAddress: "Our address:",
    address: " Wholesale - Urikzor Stroy city 3 block 10/61.",
    address2: "Market Karvon, Chirchik.",
    address3: "Kibray branch",
    day: "Mon-Sat",
    day2: "Tue-Sun",
    toxir: "Tohir",
    navruzbek: "Navruzbek",
    kamoliddin: "Kamoliddin",
    galereya: "Gallery",
  
    footer: "High quality product",
    footer1: "Menu",
    footer2: "Social network",
  
  
    category_1: "Faucets",
    category_2: "Boiler room",
  };