export const uz = {
  navbar1: "Asosiy",
  navbar2: "Biz haqimizda",
  navbar3: "Tovarlar",
  navbar4: "Aloqa",

  aboutUs: "SEENTEX KOMPANIYAMIZ HAQIDA",
  aboutUsP: ` ""SEENTEX" group kompaniyasi O'zbekistonda faoliyatini 2022-yilda boshlagan bo'lib, korxonaning rasmiy ochilish marosimi may oyida bo'lib o'tgan.
  Korxonamizning asosiy maqsadi xalqimiz talabidan kelib chiqqan xolda, sifatli va xamyonbop maxsulotlar bilan  mijozlarimiz xonadonlaridan va qalblaridan o'rin olish. Xozirgi kunda  SEENTEX brendi ostida  keng assortimentdagi smessitel va gaz qozonlarini Respublika bo'ylab yetkazib berish yo'lga qo'yilgan.
 `,

  benefist: "BIZNING AVZALLIKLARIMIZ",
  benefist1: "YUQORI SIFAT XAMYONBOP NARXLARDA",
  benefist2: "EKOLOGIK TOZA VA XAVFSIZ TOVARLAR",
  benefist3: "XALQARO KOMPANIYALAR BILAN XAMKORLIK",
  benefist4: "MIJOZLARIMIZ UCHUN AJOYIB SOVG`A VA VAUCHERLAR",

  whyWe: "Nima uchun SEENTEX?",
  whyWeP: "Italiya texnologiyasi va dizayni asosida yuqori sifatli lotundan ishlab chiqarilgan",
  garantiya: "Kafolat",
  garantiyaP: "Maxsulotlarimiz tegishli guvohnomalarga ega",

  believe: "BIZNING HAMKORLAR",

  ourAddress: "Bizning manzil",
  address: " Optom - O'rikzor Stroy shahar 3 blok 10/61.",
  address2: "Chirchiq shahridagi Roznis-Karvon bozori.",
  address3: "Qibray filiali",
  day: "Du-Sh",
  day2: "Se-Ya",
  toxir: "Toxir",
  navruzbek: "Navruzbek",
  kamoliddin: "Kamoliddin",
  galereya: "Galereya",

  footer: "Yuqori sifatli mahsulot",
  footer1: "Menyu",
  footer2: "Ijtimoiy tarmoq",




  category_1: "Smessitelar",
  category_2: "Gaz qozonlari",
};