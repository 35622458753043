import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/main.scss";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./components/Admin";
import About from "./components/About";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Tovar from "./components/Tovar";
import Map from "./components/Map";
import ScrollToTop from "./components/ScrollToTop";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about-us" element={ <About />} />
        <Route path="/contacts" element={ <>
          <div className="myTop"></div>
        <Map />
        <div className="myBottom"></div>
        </>} />
        <Route path="/productions" element={ <>
        <div className="myTop"></div>
          <Tovar />
          <div className="myBottom"></div>
        </>} />
        <Route path="/seentex_login" element={<Admin />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
